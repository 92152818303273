<template>
  <Acl :permissions="['pages-index']">
    <b-row no-gutters class="align-items-center justify-content-between mb-3">
      <!-- <b-col cols="7" class="mb-3 mb-lg-0 pb-2 pt-2 pt-lg-0 pb-lg-0">
        <a href="javascript:;"
          @click.prevent="() => showingPublished = null"
          :class="showingPublished === null ? 'text-dark font-weight-bold' : 'text-primary'">
          All
        </a>
        <a-divider type="vertical" />
        <a href="javascript:;"
          @click.prevent="() => showingPublished = 1"
          :class="showingPublished === 1 ? 'text-dark font-weight-bold' : 'text-primary'">
          Published
        </a>
        <a-divider type="vertical" />
        <a href="javascript:;"
          @click.prevent="() => showingPublished = 0"
          :class="showingPublished === 0 ? 'text-dark font-weight-bold' : 'text-primary'">
          Draft
        </a>
      </b-col> -->
      <b-col cols="5" lg="2" class="text-right pr-lg-2 mb-3 mb-lg-0">
        <b-button v-show="selectedPortals.length" @click.prevent="showDeleteConfirm(selectedPortals)" size="sm" variant="outline-danger"><i class="fe fe-trash"></i> Delete Selected</b-button>
      </b-col>
      <b-col lg="3" class="text-right">
        <a-form :form="form" @submit.prevent="search" layout="inline">
          <a-input-search v-model="q" placeholder="Search Portals"></a-input-search>
        </a-form>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <div class="px-3 card">
          <a-table
            :row-selection="{ selectedRowKeys: selectedPortals, onChange: onSelectChange }"
            :loading="loading"
            :columns="columns"
            :dataSource="portals"
            :class="$style.table"
            :pagination="pagination"
            :scroll="{ x: 900 }"
            @change="tableChanged">
            <div
              slot="filterDropdown"
              slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
              class="px-2 py-2"
            >
              <a-form-model-item class="mb-0 py-2">
                <a-range-picker
                  :value="filters.published_at"
                  format="DD/MM/YYYY"
                  @change="(date, dateString) => onDateChange(date, dateString, setSelectedKeys)">
                  <template slot="dateRender" slot-scope="current">
                    <div class="ant-calendar-date">
                      {{ current.date() }}
                    </div>
                  </template>
                </a-range-picker>
              </a-form-model-item>

              <b-row class="border-top mt-2 pt-2 mx-n2 px-2">
                <b-col cols="6">
                  <a href="javascript:;"
                    class="text-primary"
                    @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)">OK</a>
                </b-col>
                <b-col cols="6" class="text-right">
                  <a href="javascript:;"
                    class="text-primary"
                    @click="() => handleReset(clearFilters)">Reset</a>
                </b-col>
              </b-row>
            </div>
            <template slot="portalTitle" slot-scope="name">
              {{ name }}
              <!-- <b-row no-gutters>
                <b-col v-if="item.imageMedia.length || item.image_media" cols="2">
                  <img
                    class="w-100"
                    :src="`${item.image_media ? item.image_media.url : item.imageMedia[0].url}?tr=w-50,h-50`"
                    :srcset="`${item.image_media ? item.image_media.url : item.imageMedia[0].url}?tr=w-50,h-50 1x, ${item.image_media ? item.image_media.url : item.imageMedia[0].url}?tr=w-100,h-100 2x`"
                    :alt="item.image_media ? item.image_media.alt : item.imageMedia[0].alt">
                </b-col>
                <b-col class="pl-3">
                  <h2 class="mb-0 h6">
                    <router-link :title="`Edit: ${title}`" class="text-gray-6" :to="{ path: `portals/edit/${item.id}`}">
                      {{ title }}
                    </router-link>
                  </h2>
                  <small :class="item.is_published ? 'text-success' : 'text-warning'">{{ item.is_published ? 'Published' : 'Draft' }}</small>
                </b-col>
              </b-row> -->
            </template>
            <template slot="tags" slot-scope="tags">
              <div v-if="tags.length">
                <span class="badge badge-info mr-1" v-for="tag in tags" :key="tag.id">{{ tag.name }}</span>
              </div>
              <span v-else>-</span>
            </template>
            <template slot="created_at" slot-scope="created_at">
              {{ created_at | moment("D MMM YYYY,") }} <span class="text-muted small">{{ created_at | moment("h:mm A") }}</span>
            </template>
            <template slot="updated_at" slot-scope="updated_at">
              {{ updated_at | moment("D MMM YYYY,") }} <span class="text-muted small">{{ updated_at | moment("h:mm A") }}</span>
            </template>
            <template slot="action" slot-scope="action, item">
              <div>
                <router-link title="Edit" :to="{ path: `portals/edit/${item.id}`}" class="btn btn-sm btn-primary mr-2">
                  <i class="fe fe-edit" />
                </router-link>
                <button title="Delete" @click="showDeleteConfirm([item.id])" type="button" class="btn btn-sm btn-light">
                  <i class="fe fe-trash" />
                </button>
              </div>
            </template>
          </a-table>
          <div :class="$style.tableFooter" class="text-center text-lg-left pb-4 pb-lg-0">
            <span class="text-muted">Showing <b>{{ portals.length }}</b> of <b>{{ new Intl.NumberFormat().format(pagination.total) }}</b> Pages</span>
          </div>
        </div>
      </b-col>
    </b-row>
  </Acl>
</template>
<script>
import { mapState } from 'vuex'
import { Modal } from 'ant-design-vue'
import Acl from '@/components/cleanui/system/ACL'
import moment from 'moment'

export default {
  data() {
    return {
      form: this.$form.createForm(this),
      pagination: {},
      q: '',
      selectedPortals: [],
      showingPublished: null,
      sort: {
        column: '',
        value: '',
      },
      filters: {
        tags: [],
        createdBy: [],
        categories: [],
        published_at: [],
      },
    }
  },
  created() {
    const vm = this
    vm.fetch()
    // vm.$store.dispatch('category/FETCH', {
    //   portal_id: vm.settings.activePortal,
    // })
    // vm.$store.dispatch('user/FETCH', {
    //   portal_id: vm.settings.activePortal,
    // })
  },
  computed: {
    ...mapState(['settings']),
    ...mapState('portal', {
      portals: state => state.data,
    }),
    // ...mapState('user', {
    //   users: state => state.data,
    // }),
    // ...mapState('category', {
    //   categories: state => state.data,
    // }),
    loading() {
      return this.$store.state.portal.loading
    },
    columns() {
      // const vm = this
      const columns = [
        {
          title: 'Title',
          dataIndex: 'title',
          width: '20%',
          className: 'text-gray-6',
          scopedSlots: { customRender: 'portalTitle' },
        },
        {
          title: 'Domain',
          dataIndex: 'domain',
          width: '15%',
          className: 'text-gray-6',
          scopedSlots: { customRender: 'domain' },
        },
        {
          title: 'Created',
          dataIndex: 'created_at',
          className: 'text-gray-6',
          scopedSlots: { customRender: 'created_at' },
        },
        {
          title: 'Updated',
          dataIndex: 'updated_at',
          className: 'text-gray-6',
          scopedSlots: { customRender: 'updated_at' },
        },
        {
          dataIndex: 'action',
          className: 'text-right',
          scopedSlots: { customRender: 'action' },
          fixed: 'right',
        },
      ]
      return columns
    },
  },
  methods: {
    onDateChange(date, dateString, setSelectedKeys) {
      this.filters.published_at = [moment(dateString[0], 'DD-MM-YYYY'), moment(dateString[1], 'DD-MM-YYYY')]
      setSelectedKeys(dateString)
    },
    onSelectChange(selectedPortals) {
      this.selectedPortals = selectedPortals
    },
    // Filter & Search
    search() {
      const vm = this
      vm.fetch()
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      // console.log(selectedKeys, dataIndex)
      confirm()
      // this.filters.published_at = [moment(), moment()]
    },
    handleReset(clearFilters) {
      clearFilters()
      this.filters.published_at = []
    },
    fetch(payload = {}) {
      const vm = this
      vm.$store.dispatch('portal/FETCH', {
        // portal_id: vm.settings.activePortal,
        q: vm.q,
        ...payload,
      }).then((res) => {
        // console.log(res)
        const pagination = {
          ...vm.pagination,
          current: Number(res.data.page),
          total: Number(res.data.total),
        }
        vm.pagination = pagination
      })
    },
    showDeleteConfirm(ids) {
      const vm = this

      Modal.confirm({
        title: 'Are you sure delete this page?',
        content: 'This page will deleted permanently',
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk() {
          ids.forEach((id) => {
            vm.$store.dispatch('page/DELETE', id).then((res) => {
              vm.$notification.success({
                message: 'Page Deleted',
                description: 'Successfully deleted.',
              })
              const index = vm.selectedPortals.indexOf(id)
              if (index > -1) {
                vm.selectedPortals.splice(index, 1)
              }
              vm.fetch()
            })
          })
        },
        onCancel() {},
      })
    },
    tableChanged(pagination, filters, sorter) {
      // console.log('table changed', filters)
      const vm = this
      vm.fetch({
        page: pagination.current,
        perPage: pagination.pageSize,
      })
    },
  },
  watch: {
    'settings.activePortal': function() {
      const vm = this
      vm.fetch()
    },
    showingPublished(showingPublished) {
      const vm = this
      vm.fetch({
        is_published: showingPublished,
      })
    },
  },
  destroyed() {
    this.$store.commit('portal/CLEAR_DATA')
  },
  components: {
    Acl,
  },
}
</script>
<style lang="scss" module>
// Import mixins
@import "~bootstrap/scss/bootstrap-grid";
@import "~bootstrap/scss/mixins";

.table {
  td {
    vertical-align: top;

    h5 {
      font-size: 16px;
      margin-bottom: 2px;
    }
  }
  &Footer {
    @include media-breakpoint-up(lg) {
      margin-top: -45px;
      margin-bottom: 45px;
    }
  }
}
</style>
